<template>
    <section id="content">

        <div class="content-wrap pb-0">

            <div class="container">
                <div class="row align-items-end">
                    <div class="col-lg-6">
                        <div class="badge badge-pill badge-default">Marketing</div>
                        <h2 class="display-6 font-weight-bold">It's better to use our automated testing services for the best test of your application!</h2>
                        <p>Due to the fact that the applications defects are found too late, more than a half of all software projects do not meet the objectives and do not fully perform the functions for which these applications were developed. To solve such problems, the test automation services are used. These services verify the results of the application and provide a mechanism for a consistently repeating of the test procedure. This can be effective both for the regression testing and during the development. We do not just create the automation scripts in our company, we also focus on creating the end-to-end life-cycle automation solutions which provide the high-quality software products..</p>

                    </div>
                    <div class="col-lg-6 mt-4 mt-lg-0">
                        <img src="../../assets/demos/seo/images/services/social.svg" alt="Image">
                    </div>
                </div>

                <div class="clear line my-6"></div>

                <div class="heading-block border-bottom-0 center">
                    <div class="badge badge-pill badge-default">We help You to make Your applications function without errors, in the most efficient way!</div>
                    <h3 class="nott ls0">WHAT WE ARE DOING:</h3>
                </div>
                <div class="clear"></div>
                <div class=" row mt-4 col-mb-50 mb-0">
                    <div class="col-sm-6 col-lg-6">
                        <div class="feature-box">
                            <div class="fbox-icon mb-4">
                                <a href="#"><i class="icon-file-alt1 si-colored si-gplus border-0"></i></a>
                            </div>
                            <div class="fbox-content">
                                <h3 class="nott">Testing the User Interface Automation Services</h3>
                                <p>Our testing experience allows us to integrate the test automation for web and mobile projects which performs the user interface testing. We use SeleniumWebDriver for the web projects testing, and as for the projects iOS we use iOSAutomationFramework from Apple.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="feature-box">
                            <div class="fbox-icon mb-4">
                                <a href="#"><i class="icon-mobile si-colored si-instagram border-0"></i></a>
                            </div>
                            <div class="fbox-content">
                                <h3 class="nott">Automatic testing RESTAPI</h3>
                                <p>We provide the automated testing services RESTAPI, which ensure the reliability and the performance during the test.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="feature-box">
                            <div class="fbox-icon mb-4">
                                <a href="#"><i class="icon-search border-0"></i></a>
                            </div>
                            <div class="fbox-content">
                                <h3 class="nott">Automatic Unit tests</h3>
                                <p>A significant role is played by the automated testing of business modules and code functionality. We offer the service level automation and the data access testing for the multi-level MVC applications. An automatic Unit Testing plays an integral role in the continuous integration and delivery</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="feature-box">
                            <div class="fbox-icon mb-4">
                                <a href="#"><i class="icon-browser si-colored si-appstore border-0"></i></a>
                            </div>
                            <div class="fbox-content">
                                <h3 class="nott">Creating of the automated test data</h3>
                                <p>An intelligent and automated test data creation mechanism helps to create the useful test data. A well-designed test data allows you to cover all possible test cases and improve the performance of your product.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="clear"></div>
            <ContactFragment />
        </div>
    </section>
</template>

<script>

    import ContactFragment from './../ContactFragment.vue'
    export default {
        name: "Testing",

        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Testing',
            // all titles will be injected into this template
            titleTemplate: '%s | Crafty Tech'
        },
        components: {
            ContactFragment,

        },
    }
</script>

<style scoped>
    .contact {
        background: white;
        background-size: cover;
        padding: 100px 0;
    }
    .contact::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 45%;
        width: 80%;
        height: 451px;
        background-size: 1280px 451px;
        background-repeat: no-repeat;
        background-image: url('../../assets/demos/seo/images/hero/hero-3.svg');
    }
</style>